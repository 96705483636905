import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "justify",
    padding: "0 20px",
    wordBreak: "break-word",
    a: {
      color: "#E60005",
      fontWeight: 500,
      fontSize: "18px",
      textDecoration: "none",
    },
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "40px",
    mt: { xs: 4 },
    mb: 2,
  },
  pageHeader: {
    mt: 4,
    mb: 2,
  },
  pageSubHeader: {
    mt: 4,
    mb: 2,
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLink: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLabel: {
    fontSize: "22px",
    fontWeight: "700",
    "& span": {
      marginLeft: "5px",
    },
  },
  navigation: {
    fontSize: "15px",
    "& span": {
      color: "#2276D0",
    },
  },
  linkColor: {
    color: "#2276D0",
    fontSize: "18px",
    fontWeight: "700",
  },
  link: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: "18px",
    marginLeft: "5px",
  },
  URLLink: {
    color: "#E60005",
    fontWeight: 500,
    textDecoration: "none",
  },
  secondSection: {
    backgroundColor: "#FBFBFB",
    paddingTop: "48px",
  },
};

const TermsOfUseSection = () => {
  const { t } = useTranslation("base");

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box
        width={"100%"}
        mt={{ xs: 2, md: "60px" }}
        display={"flex"}
        justifyContent={"center"}
        sx={stylesComponent.secondSection}
      >
        <Box maxWidth={"600px"}>
          <Typography sx={stylesComponent.pageHeader} variant="h1">
            {t("TermsOfUse.Title")}
          </Typography>
          <Box>
            {(
              t("TermsOfUse.Content.0", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.0_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.1.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.1", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.1_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.2.Header")}
            </Typography>
            <Typography mb={1}>
              {t("TermsOfUse.Content.2.Content.0")}
            </Typography>
            <ul>
              {(
                t("TermsOfUse.Content.2", { returnObjects: true }) as {
                  Content: string[];
                }
              ).Content.filter((v, i) => i !== 0).map((text: string, index) => (
                <li key={`TermsOfUse.Content.2_${index}`}>
                  <Typography mb={1}>{text}</Typography>
                </li>
              ))}
            </ul>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.3.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.3", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.3_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.4.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.4", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.4_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.5.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.5", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.5_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.6.Header")}
            </Typography>
            <Typography>{t("TermsOfUse.Content.6.Content.0")}</Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.7.Header")}
            </Typography>
            <Typography mb={1}>
              {t("TermsOfUse.Content.7.Content.0")}
            </Typography>
            <Typography mb={1}>
              {t("TermsOfUse.Content.7.Content.1")}
            </Typography>
            <ul>
              {(
                t("TermsOfUse.Content.7", { returnObjects: true }) as {
                  Content: string[];
                }
              ).Content.filter((v, i) => i > 1 && i < 4).map(
                (text: string, index) => (
                  <li key={`TermsOfUse.Content.7_${index}`}>
                    <Typography mb={1}>{text}</Typography>
                  </li>
                )
              )}
            </ul>
            <Typography mb={1}>
              {t("TermsOfUse.Content.7.Content.4")}
            </Typography>
            <ul>
              {(
                t("TermsOfUse.Content.7", { returnObjects: true }) as {
                  Content: string[];
                }
              ).Content.filter((v, i) => i > 4 && i < 7).map(
                (text: string, index) => (
                  <li key={`TermsOfUse.Content.7_${index}`}>
                    <Typography mb={1}>{text}</Typography>
                  </li>
                )
              )}
            </ul>
            <Typography mb={1}>
              {t("TermsOfUse.Content.7.Content.7")}
            </Typography>
            <ul>
              {(
                t("TermsOfUse.Content.7", { returnObjects: true }) as {
                  Content: string[];
                }
              ).Content.filter((v, i) => i > 7 && i < 9).map(
                (text: string, index) => (
                  <li key={`TermsOfUse.Content.7_${index}`}>
                    <Typography mb={1}>{text}</Typography>
                  </li>
                )
              )}
            </ul>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.8.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.8", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.8_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.9.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.9", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.9_${index}`}>
                {text}
              </Typography>
            ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("TermsOfUse.Content.10.Header")}
            </Typography>
            {(
              t("TermsOfUse.Content.10", { returnObjects: true }) as {
                Content: string[];
              }
            ).Content.map((text: string, index) => (
              <Typography mb={1} key={`TermsOfUse.Content.10_${index}`}>
                {text}
              </Typography>
            ))}
          </Box>
          <Box mb={{ xs: 4, md: "120px" }}></Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default TermsOfUseSection;
