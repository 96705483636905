import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSchools } from "../../context/SchoolContext";
import moment from "moment";

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "#EBF5FF",
    paddingTop: "48px",
    padding: "48px 10px",
  },
  terms: {
    fontSize: "15px",
    fontWeight: "700",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
  termClose: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#E60005",
  },
  startTermInfo: {
    color: "#E60005",
    fontSize: "15px",
    fontWeight: "700",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
};

type Props = {
  isChatOpen: boolean;
  currentTime: any;
};

const HeaderSection = ({
  isChatOpen,
  currentTime,
}: Props): React.ReactElement => {
  const { t } = useTranslation("base");
  const { setOpenChatAdviceGDPRModal, setSelectChatAdviceGDRPModalWhatsApp } =
    useSchools();

  const handleSelectWhatsappChat = () => {
    setOpenChatAdviceGDPRModal(true);
    setSelectChatAdviceGDRPModalWhatsApp(true);
  };

  // const handleSelectSMSChat = () => {
  //   setOpenChatAdviceGDPRModal(true);
  //   setSelectChatAdviceGDRPModalWhatsApp(false);
  // };

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mt={2}>
            <Typography variant="h1">{t("Header")}</Typography>
          </Box>
          <Box mt={2}>
            <Typography>{t("SubHeader")}</Typography>
          </Box>
          <Box mt={4}>
            {isChatOpen ? (
              <Button
                sx={{ margin: 0 }}
                onClick={() => handleSelectWhatsappChat()}
              >
                {t("Buttons.0")}
                <OpenInNewIcon sx={{ ml: 2 }} />
              </Button>
            ) : (
              <Button
                disabled
                sx={{ margin: 0 }}
                onClick={() => handleSelectWhatsappChat()}
              >
                {t("Buttons.0")}
                <OpenInNewIcon sx={{ ml: 2 }} />
              </Button>
            )}
          </Box>
          {/* SMS button --------> */}
          {/* <Box mt={2}>
            <Button
              disabled={isChatOpen}
              sx={{ margin: 0 }}
              onClick={() => handleSelectSMSChat()}
            >
              {t("Buttons.1")}
              <OpenInNewIcon sx={{ ml: 2 }} />
            </Button>
          </Box> */}
          <Box mt={3}>
            {/* remove this message when set chat active after january 2024 */}
            {!isChatOpen && currentTime && (
              <Typography mb={2} sx={stylesComponent.termClose}>
                {t("Terms.0")}
              </Typography>
            )}
            {isChatOpen && currentTime && (
              <Typography sx={stylesComponent.terms}>
                {currentTime.germanName}:{" "}
                <span>
                  {moment(currentTime.open, "HH").format("HH")} -{" "}
                  {moment(currentTime.close, "HH").format("HH")} Uhr
                </span>
              </Typography>
            )}
            {/* <Typography sx={stylesComponent.terms}>
              {t("Terms.3")} <span> {t("Terms.4")}</span>
              </Typography> */}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default HeaderSection;
