import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardActions";
import ChatIcon from "../../assets/chatIcon.svg";
import TermsIcon from "../../assets/termsIcon.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IntroImage from "../../assets/Background_Mobile.jpg";
import IntroImageDesktop from "../../assets/Background_Desktop.jpg";
import { useNavigate } from "react-router";

const stylesComponent = {
  introduction: {
    color: "#fff",
    backgroundImage: {
      xs: `url(${IntroImage})`,
      md: `url(${IntroImageDesktop})`,
    },
    backgroundPosition: `top center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px, 10px",
  },
  headerWrapper: {
    width: { xs: "360px", md: "490px" },
  },
  header: {
    fontWeight: "700",
    fontSize: { xs: "30px", md: "48px" },
    p: 1,
  },
  cardWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: { xs: "20px", md: "80px" },
    mt: { xs: "180px", md: "108px" },
  },
  card: {
    width: { md: "430px" },
    padding: "20px",
    paddingRight: "0",
    marginBottom: "20px",
    borderRadius: "10px",
    cursor: "pointer",
    textAlign: "left",
  },
  cardContent: {
    fontSize: { xs: "18px", md: "20px" },
    fontWeight: "700",
    marginLeft: "15px",
    lineHeight: "24px",
    p: 0,
  },
  cardIcon: {
    alignSelf: "baseline",
    "& img": {
      width: { xs: "32px", md: "48px" },
      height: { xs: "32px", md: "48px" },
    },
  },
  forwardIcon: {
    height: "18px",
    color: "#B4B4B4",
  },
  startInfoLabel: {
    position: "relative",
    top: 14,
    ml: { xs: 22, md: 33 },
    height: "32px",
    width: "155px",
    background: "#E60005",
    borderRadius: "24px",
  },
};

const IntroductionSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const navigate = useNavigate();

  const navigateToLink = (link: string): void => {
    window.scrollTo(0, 0);
    navigate(link);
  };

  return (
    <Grid container sx={stylesComponent.introduction} textAlign="center">
      <Grid item md={6}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box sx={stylesComponent.headerWrapper}>
            <Typography
              mt={{ xs: 4, md: "80px" }}
              sx={stylesComponent.header}
              textAlign={{ xs: "center", md: "left" }}
              variant="h1"
            >
              {t("Introduction.0")}
            </Typography>
          </Box>
        </Box>

        <Box sx={stylesComponent.cardWrapper}>
          <Box sx={stylesComponent.headerWrapper}>
            {/* <Box sx={stylesComponent.startInfoLabel}>
              <Typography
                fontSize={"15px"}
                fontWeight={700}
                mt={"4px"}
                lineHeight={"2"}
              >
                {t("STARTINFOLABEL")}
              </Typography>
            </Box> */}

            <Card sx={stylesComponent.card}>
              <CardContent onClick={() => navigateToLink("/chat-beratung")}>
                <Box sx={stylesComponent.cardIcon}>
                  <img src={ChatIcon} alt="chat" />
                </Box>
                <Typography sx={stylesComponent.cardContent}>
                  {t("Introduction.1")}
                </Typography>
                <ArrowForwardIosIcon sx={stylesComponent.forwardIcon} />
              </CardContent>
            </Card>
            <Card sx={stylesComponent.card}>
              <CardContent onClick={() => navigateToLink("/terminbuchung")}>
                <Box sx={stylesComponent.cardIcon}>
                  <img src={TermsIcon} alt="chat" />
                </Box>
                <Typography sx={stylesComponent.cardContent}>
                  {t("Introduction.2")}
                </Typography>
                <ArrowForwardIosIcon sx={stylesComponent.forwardIcon} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default IntroductionSection;
