import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Peers from "../../assets/peers.jpeg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "white",
    pt: 8,
    pb: 8,
  },
  sectionHeader: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "28px",
  },
  learnMoreBtn: {
    color: "#E60005",
    fontSize: "18px",
    fontWeight: "700",
    cursor: "pointer",
  },
  icon: {
    color: "#E60005",
    fontSize: "12px",
  },
};

const JoinPeersSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const isMobile = useMediaQuery("(max-width:1460px)", { noSsr: true });

  return (
    <Grid item xs={12} mt={2} mb={6} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        {isMobile ? (
          <Box maxWidth={"600px"}>
            <img
              src={Peers}
              alt="peers"
              height={199}
              width={350}
              style={{ borderRadius: "5%" }}
            />
            <Box mt={4} pr={2} pl={2} sx={stylesComponent.sectionHeader}>
              {t("JoinPeers.0")}
            </Box>
            <Box mt={2} pr={2} pl={2}>
              {t("JoinPeers.1")}
            </Box>
            <Typography mt={3} sx={stylesComponent.learnMoreBtn}>
              {t("LearnMoreBtn")}
              <ArrowForwardIosIcon sx={stylesComponent.icon} />
            </Typography>
          </Box>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            spacing={4}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <img
                src={Peers}
                alt="peers"
                height={220}
                width={350}
                style={{ borderRadius: "5%" }}
              />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <Box mt={2} sx={stylesComponent.sectionHeader}>
                {t("JoinPeers.0")}
              </Box>
              <Box mt={2}>{t("JoinPeers.1")}</Box>
              <Typography mt={3} sx={stylesComponent.learnMoreBtn}>
                {t("LearnMoreBtn")}
                <ArrowForwardIosIcon sx={stylesComponent.icon} />
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default JoinPeersSection;
