import React from "react";
import {
  Box,
  Grid,
  Drawer,
  useMediaQuery,
  Link,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LogoIcon from "../../assets/Logo_Final.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DrawerMenu from "./DrawerMenu";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  contentWrapper: {
    position: "sticky",
    zIndex: "998",
    top: "0",
    overflow: "inherit",
    width: "100%",
    backgroundColor: "#fff",
    borderBottom: "1px solid #D9D9D9",
  },
  header: {
    display: "flex",
    height: { md: "100px", xs: "65px" },
    alignItems: "center",
    textAlign: "center",
    maxWidth: "700px",
    margin: { md: "auto" },
  },
  linksBox: {
    display: "flex",
    height: { md: "100px", xs: "65px" },
    alignItems: "center",
    textAlign: "center",
    maxWidth: "1000px",
    margin: { md: "auto" },
  },
  menuItem: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "26px",
    marginRight: { xs: "25px", md: "40px" },
    cursor: "pointer",
    "& .MuiTypography-root": {
      color: "#000",
    },
  },
  emptyBox: {
    width: "24px",
  },
  menuIcon: {
    width: "24px",
    height: "24px",
    marginRight: "24px",
    cursor: "pointer",
  },
  backIcon: {
    cursor: "pointer",
  },
  backIconBox: {
    width: "24px",
    height: "24px",
    paddingTop: "2px",
    marginLeft: "24px",
  },
};

const Header = (): React.ReactElement => {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("base");
  const [isDrawerMenuOpen, setIsDrawerMenuOpen] =
    React.useState<boolean>(false);
  const toggleDrawer = (open: boolean) => {
    setIsDrawerMenuOpen(open);
  };

  const menuList = () => (
    <DrawerMenu setIsDrawerMenuOpen={setIsDrawerMenuOpen} />
  );

  const navigateToLink = (link: string): void => {
    window.scrollTo(0, 0);
    navigate(link);
  };

  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  const menuItemsObject = t("DesktopMenu", { returnObjects: true });
  const navigationLinksList = [
    "chat-beratung",
    "terminbuchung",
    "faq",
    "ueber-uns",
    "mitmachen",
  ];

  if (isMobile) {
    return (
      <Box sx={stylesComponent.contentWrapper}>
        <Grid item xs={12}>
          <Box
            sx={stylesComponent.header}
            justifyContent={isMobile ? "space-between" : "space-around"}
          >
            <Box sx={stylesComponent.backIconBox}>
              {location.pathname !== "/" ? (
                <ArrowBackIosIcon
                  sx={stylesComponent.backIcon}
                  onClick={() => navigateToLink("/")}
                />
              ) : (
                <Box sx={stylesComponent.emptyBox}>&nbsp;</Box>
              )}
            </Box>

            <Link underline="none" href="/">
              <img
                src={LogoIcon}
                alt="Realtalk"
                onClick={() => navigateToLink("/")}
              />
            </Link>
            <MenuIcon
              sx={stylesComponent.menuIcon}
              onClick={() => toggleDrawer(!isDrawerMenuOpen)}
            />
          </Box>

          <Drawer
            anchor="right"
            open={isDrawerMenuOpen}
            variant="temporary"
            onClose={() => setIsDrawerMenuOpen(false)}
          >
            {menuList()}
          </Drawer>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Grid container textAlign="center">
        <Grid item lg={3} md={3} xl={6} textAlign="center">
          <Box
            sx={stylesComponent.header}
            justifyContent={{ md: "space-around" }}
          >
            <Link underline="none" href="/" pl={2}>
              <img
                src={LogoIcon}
                alt="Realtalk"
                width={"160px"}
                onClick={() => navigateToLink("/")}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item md={9} lg={9} xl={6}>
          <Box
            sx={stylesComponent.linksBox}
            justifyContent={{ md: "end", xl: "space-around" }}
          >
            <Box textAlign="left" ml={4} display={"flex"} flexDirection={"row"}>
              {Object.values(menuItemsObject).map((menuItem, itemIndex) => (
                <Typography key={itemIndex} sx={stylesComponent.menuItem}>
                  <Link
                    underline="none"
                    href={`/${navigationLinksList[itemIndex]}`}
                    rel="noopener noreferrer"
                  >
                    {menuItem}
                  </Link>
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default Header;
