import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Trans, useTranslation } from "react-i18next";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "justify",
    padding: "0 20px",
    wordBreak: "break-word",
    a: {
      color: "#E60005",
      fontWeight: 500,
      fontSize: "18px",
      textDecoration: "none",
    },
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "40px",
    mt: { xs: 4 },
    mb: 2,
  },
  pageHeader: {
    mt: 4,
    mb: 2,
  },
  pageSubHeader: {
    mt: 4,
    mb: 2,
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLink: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLabel: {
    fontSize: "22px",
    fontWeight: "700",
    "& span": {
      marginLeft: "5px",
    },
  },
  navigation: {
    fontSize: "15px",
    "& span": {
      color: "#2276D0",
    },
  },
  linkColor: {
    color: "#2276D0",
    fontSize: "18px",
    fontWeight: "700",
  },
  link: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: "18px",
    marginLeft: "5px",
  },
  URLLink: {
    color: "#E60005",
    fontWeight: 500,
    textDecoration: "none",
  },
};

const DataProtectionSection = () => {
  const { t } = useTranslation("base");

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mt={{ xs: 4, md: "120px" }}>
            <Typography sx={stylesComponent.pageHeader} variant="h1">
              {t("DataProtection.Title")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("DataProtection.Content.0.Header")}
            </Typography>
            <Trans i18nKey="DataProtection.0.Content.0">
              <Typography mb={1}>
                Wir, der Deutsches Rotes Kreuz e.V. (nachfolgend: „
                <b>die Organisation</b>“, „<b>wir</b>“ oder „<b>uns</b>“
                genannt) nehmen den Schutz Ihrer personenbezogenen Daten ernst
                und möchten Sie an dieser Stelle über den Datenschutz in unserer
                Organisation informieren.
              </Typography>
            </Trans>
            <Trans i18nKey="DataProtection.0.Content.1">
              <Typography mb={1}>
                Nach der EU-Datenschutz-Grundverordnung (Verordnung (EU)
                2016/679; nachfolgend: „<b>DS-GVO</b>“) bestehen Pflichten, um
                den Schutz personenbezogener Daten der von einer Verarbeitung
                betroffenen Person (wir sprechen Sie als betroffene Person
                nachfolgend auch mit „<b>Kunde</b>“, „<b>Nutzer</b>“, „
                <b>Sie</b>“, „<b>Ihnen</b>“ oder „<b>Betroffener</b>“ an)
                sicherzustellen. Soweit wir entweder alleine oder gemeinsam mit
                anderen über die Zwecke und Mittel der Datenverarbeitung
                entscheiden, umfasst dies vor allem die Pflicht, Sie transparent
                über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der
                Verarbeitung zu informieren (vgl. Art. 13 und 14 DS-GVO).
              </Typography>
            </Trans>
            <Trans i18nKey="DataProtection.0.Content.2">
              <Typography mb={1}>
                Mit dieser Erklärung (nachfolgend: „<b>Datenschutzhinweise</b>“)
                informieren wir Sie darüber, in welcher Weise Ihre
                personenbezogenen Daten von uns verarbeitet werden.
              </Typography>
            </Trans>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("DataProtection.Content.1.Header")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.1.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.1.Content.0")}
            </Typography>
            <ul>
              {(
                t("DataProtection.Content.1.Content.1", {
                  returnObjects: true,
                }) as { List: string[] }
              ).List.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.1.Content.1_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ul>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.2.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.2.Content.0")}
            </Typography>
            <Typography mb={1}>
              {(
                t("DataProtection.Content.2.Content.1", {
                  returnObjects: true,
                }) as { Lines: string[] }
              ).Lines.map((text: string, index) => (
                <React.Fragment
                  key={`DataProtection.Content.2.Content.1_${index}`}
                >
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            <Typography mb={1}>
              {(
                t("DataProtection.Content.2.Content.2", {
                  returnObjects: true,
                }) as { Lines: string[] }
              ).Lines.map((text: string, index) => (
                <React.Fragment
                  key={`DataProtection.Content.2.Content.2_${index}`}
                >
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            <Trans i18nKey="DataProtection.0.Content.2">
              <Typography mb={1}>
                Weitere Angaben zu unserer Organisation entnehmen Sie bitte den
                Impressumsangaben auf unserer{" "}
                <Link
                  href="https://www.drk.de/service/impressum/"
                  target="_blank"
                  underline="none"
                >
                  Internetseite
                </Link>
                .
              </Typography>
            </Trans>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.3.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.3.Content.0")}
            </Typography>
            <Typography mb={1}>
              {(
                t("DataProtection.Content.3.Content.1", {
                  returnObjects: true,
                }) as { Lines: string[] }
              ).Lines.map((text: string, index) => (
                <React.Fragment
                  key={`DataProtection.Content.3.Content.1_${index}`}
                >
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.3.Content.2")}
            </Typography>
            <Typography mb={1}>
              {(
                t("DataProtection.Content.3.Content.3", {
                  returnObjects: true,
                }) as { Lines: string[] }
              ).Lines.map((text: string, index) => (
                <React.Fragment
                  key={`DataProtection.Content.3.Content.3_${index}`}
                >
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.4.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.4.Content.0")}
            </Typography>
            <ul>
              {(
                t("DataProtection.Content.4.Content.1", {
                  returnObjects: true,
                }) as { List: string[] }
              ).List.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.4.Content.1_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ul>
            <Typography mb={1}>
              {t("DataProtection.Content.4.Content.2")}
            </Typography>
            <Trans i18nKey="DataProtection.4.Content.3">
              <Typography mb={1}>
                <b>
                  Allgemeine Hinweise zu den Rechtsgrundlagen der
                  Datenverarbeitung auf dieser Website
                </b>
              </Typography>
            </Trans>
            <Typography mb={1}>
              {t("DataProtection.Content.4.Content.4")}
            </Typography>
            {(
              t("DataProtection.Content", { returnObjects: true }) as {
                SubHeader: string;
                Content: string[];
              }[]
            )
              .filter((i, index) => index > 4 && index < 12)
              .map((item: { SubHeader: string; Content: string[] }, index) => (
                <React.Fragment key={`DataProtection.Content.${index}`}>
                  <Typography sx={stylesComponent.pageSubHeader} variant="h3">
                    {item.SubHeader}
                  </Typography>
                  {item.Content.map((c, i) => (
                    <Typography
                      mb={1}
                      key={`DataProtection.Content.${index}_${i}`}
                    >
                      {c}
                    </Typography>
                  ))}
                </React.Fragment>
              ))}
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.12.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.12.Content.0")}
            </Typography>
            <ul>
              {(
                t("DataProtection.Content.12.Content.1", {
                  returnObjects: true,
                }) as { List: string[] }
              ).List.map((text: string, index) => (
                <li key={`DataProtection.Content.12.Content.1_${index}`}>
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ul>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.13.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.13.Content.0")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("DataProtection.Content.14.Header")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.14.SubHeader")}
            </Typography>
            <ol>
              {(
                t("DataProtection.Content.14.Content.0", {
                  returnObjects: true,
                }) as { Numbers: { Start: number; Content: string[] } }
              ).Numbers.Content.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.14.Content.0_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ol>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.15.SubHeader")}
            </Typography>
            <ol>
              {(
                t("DataProtection.Content.15.Content.0", {
                  returnObjects: true,
                }) as { Numbers: { Start: number; Content: string[] } }
              ).Numbers.Content.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.15.Content.0_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ol>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.16.SubHeader")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.16.Content.0")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.1")}
            </Typography>
            <ul>
              {(
                t("DataProtection.Content.16.Content.2", {
                  returnObjects: true,
                }) as { List: string[] }
              ).List.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.16.Content.2_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ul>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.3")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.16.Content.4")}</b>
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.16.Content.5")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.6")}
            </Typography>
            <ul>
              {(
                t("DataProtection.Content.16.Content.7", {
                  returnObjects: true,
                }) as { List: string[] }
              ).List.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.16.Content.7_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ul>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.8")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.9")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.16.Content.10")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.11")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.16.Content.12")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.16.Content.13")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.16.Content.14")}</b>
            </Typography>
            <ol>
              {(
                t("DataProtection.Content.16.Content.15", {
                  returnObjects: true,
                }) as { Numbers: { Content: string[] } }
              ).Numbers.Content.map((text: string, index) => (
                <li
                  style={{ listStyle: "none" }}
                  key={`DataProtection.Content.16.Content.15_${index}`}
                >
                  <Typography>{text}</Typography>
                </li>
              ))}
            </ol>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.17.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.17.Content.0")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.17.Content.1")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.17.Content.2")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.17.Content.3")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.17.Content.4")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.17.Content.5")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.17.Content.6")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.18.SubHeader")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.18.Content.0")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h3">
              {t("DataProtection.Content.19.SubHeader")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.0")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.1")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.2")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.3")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.4")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.5")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.6")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.7")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.8")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.9")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.10")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.11")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.12")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.13")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.14")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.15")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.16")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.17")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.18")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.19")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.20")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.21")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.22")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.23")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.24")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.25")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.26")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.27")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.28")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.29")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.30")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.31")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.32")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.33")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.34")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.35")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.36")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.37")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.38")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.39")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.40")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.41")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.42")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.43")}
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.44")}
            </Typography>
            <Typography mb={1}>
              <b>{t("DataProtection.Content.19.Content.45")}</b>
            </Typography>
            <Typography mb={1}>
              {t("DataProtection.Content.19.Content.46")}
            </Typography>
          </Box>
          <Box mb={{ xs: 4, md: "120px" }}></Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default DataProtectionSection;
